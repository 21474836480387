<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">截屏备案记录</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ $route.query.projectName }}截屏备案记录</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="截屏日期" class="searchboxItem ci-full">
              <span class="itemLabel">截屏日期:</span>
              <el-date-picker
                v-model="dateRange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                size="small"
              ></el-date-picker>
            </div>
            <div title="截屏类型" class="searchboxItem ci-full">
              <span class="itemLabel">截屏类型:</span>
              <el-select
                v-model="screenshotObject"
                placeholder="请选择截屏类型"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in screenshotObjectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df" style="padding-right: 15px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
          <div class="searchbox" style="margin-left: 10px">
            <el-button
              type="primary"
              class="bgc-bv"
              round
              @click="BulkDownload()"
              >批量下载</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column label="截屏类型" align="center">
                <span slot-scope="scope">
                  {{
                    $setDictionary(
                      "SCREENSHOT_OBJECT",
                      scope.row.screenshotObject
                    )
                  }}
                </span>
              </el-table-column>
              <el-table-column
                label="截屏日期"
                align="left"
                show-overflow-tooltip
                prop="screenshotTime"
              >
                <template slot-scope="scope">{{
                  scope.row.screenshotTime | momentWu
                }}</template>
              </el-table-column>
              <el-table-column
                label="操作人"
                align="left"
                show-overflow-tooltip
                prop="screenshotOperator"
              />
              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 15px"
                    @click="lookupTrainCert(scope.row.screenshotId)"
                    >查看</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="查看截屏备案表"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
      ><div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import PageNum from "@/components/PageNum.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "ScreenCaptureInfo",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dateRange: [],
      screenshotObject:"",
      dialogCert: false,
      projectName: "",
      compId: "",
      CompanyList: [],
      // 截屏类型下拉数据
      screenshotObjectList: [],
    };
  },
  created() {
    this.getInvoicingState();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取字典 - 截屏类型下拉数据
    getInvoicingState() {
      let arr = this.$setDictionary("SCREENSHOT_OBJECT", "list");
      for (const key in arr) {
        // 监管
        if(JSON.parse(sessionStorage.getItem("userJson")).roleId == 2) {
          if(key == "10" || key == "30"){
            this.screenshotObjectList.push({
              label: arr[key],
              value: key,
            });
          }
        }
        // 机构
        if(JSON.parse(sessionStorage.getItem("userJson")).roleId == 3) {
          if(key == "20" || key == "30"){
            this.screenshotObjectList.push({
              label: arr[key],
              value: key,
            });
          }
        }
      }
    },
    // 初始化 - 获取列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.$route.query.projectId) {
        params.projectId = this.$route.query.projectId;
      }
      if (this.dateRange) {
        params.startDate = this.dateRange[0];
        params.endDate = this.dateRange[1];
      }
      if (this.screenshotObject) {
        params.screenshotObject = this.screenshotObject;
      }
      this.doFetch({
        url: "/screenshot/queryScreenShotTimeList",
        params,
        pageNum,
      });
    },
    // 查看 - pdf
    lookupTrainCert(screenshotId) {
      let parma = {
        screenshotId,
      };
      this.$post("/screenshot/queryScreenShotDetail", parma).then((res) => {
        if (res.status == 0) {
          this.dialogCert = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-cert");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    // 批量下载
    BulkDownload() {
      const parmar = {
        projectId: this.$route.query.projectId,
      };
      this.$post("/screenshot/batchPrint", parmar)
        .then((res) => {
          if (res.status == "0") {
            if (!this.downloadItems.includes(res.data.taskId)) {
              this.$store.dispatch("pushDownloadItems", res.data.taskId);
            } else {
              this.$message.warning(
                "[" + res.data.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(re.message);
          }
        })
        .catch(() => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
